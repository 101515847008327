<template>
    <div class="app">
        <div class="top" v-if="success">
            <div class="img">
                <img width="81px" height="82px" src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/img/success.png" alt="">
            </div>
            <p style="font-weight: bold;margin: 20px 0px;">支付成功</p>
            <div>
                <p>1. 支付成功后，请您返回 <span class="orange-txt">易享家 APP 查看订单详情; </span></p>
                <p>2. 我方顾问将于 <span class="orange-txt">30分钟内与您取得联系</span>, 包括但不限于您预留的微信号、手机、邮箱等方式; </p>
                <p>3. 您也可以通过咨询 <span class="orange-txt">在线客服</span> 来获得更多信息及帮助; </p>
            </div>
        </div>

        <div class="top" v-if="success==false">
            <div class="img">
                <img width="81px" height="82px" src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/img/fail.png" alt="">
            </div>
            <p style="font-weight: bold;">支付未完成, 请重新支付</p>
            <p style="color: #777777; padding: 0px; margin: 0px;">请点击下方按钮重新发起支付</p>
            <div style="margin-top: 100px;">
                <span class="repay-btn" @click="rePay">重新使用支付宝付款</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "success",
        data() {
            return {
                success: null,
                repayLink: ''
            }
        },
        created() {
            let orderNo = this.$route.query.orderNo;
            let result = this.$route.query.result;
            if (result==='success') {
                // paypal 跳转过来只会成功
                this.success = true

                // vant.Toast.loading({
                //     duration: 0, // 持续展示 toast
                //     message: '加载中...',
                //     forbidClick: true,
                // });
                //
                // let fly = new Fly()
                // fly.config.withCredentials = true
                // fly.get(window.DOMAIN + '/rest/pay/alipay/getPayUrl', {
                //     orderNo: orderNo
                // }).then((res) => {
                //     // 手动清除 Toast
                //     vant.Toast.clear();
                //     res = res.data
                //     if (res.code == 0) {
                //         if (res.data.status == 'PAID') {
                //             this.success = true
                //         } else {
                //             this.success = false
                //             this.repayLink = res.data.payUrl
                //         }
                //     } else {
                //         vant.Toast('服务错误，请稍后再试!')
                //     }
                // });

            } else {
                this.success = false

                this.$failToast('订单号错误!')
            }

        },
        methods: {
            rePay() {
                window.location.href = this.repayLink
            }
        }
    }
</script>

<style scoped lang="scss">
    .app {
        padding: 30px 20px;
        height: 100%;
        font-size: 14px;
        color: #5E5E5F;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
    }

    .top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
    }

    .orange-txt {
        color: #F67F31;
    }

    .bottom {
        text-align: center;
        position: absolute;
        bottom: 20px;
    }

    .repay-btn {
        padding: 10px 50px;
        color: white;
        background-color: #3D84FF;
        border-radius: 5px;
    }
</style>
